<template>
  <div class="rightLink">
    <div class="bottomDiv">
      <div class="title">物联网卡查询</div>
      <div class="formDiv" style="width:1550px">
        <el-form label-position="right" label-width="110px">
          <el-row :gutter="20" style="margin-bottom: 10px">
            <el-col :span="2"></el-col>
            <el-col :span="6">
              <el-form-item label="卡号:">
                <el-input
                  v-model="FormData.card_number"
                  placeholder="请输入卡号"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="卡运营商:">
                <el-select
                  v-model="FormData.operator"
                  placeholder="请选择运营商"
                  clearable
                >
                  <el-option
                    v-for="oper in operList"
                    :key="oper.value"
                    :value="oper.value"
                    :label="oper.label"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="流量类型:">
                <el-select
                  v-model="FormData.flow_type "
                  placeholder="请选择流量类型"
                  clearable
                >
                  <el-option
                    v-for="flow in flowList"
                    :key="flow.value"
                    :value="flow.value"
                    :label="flow.label"
                  />
                </el-select>
              </el-form-item>
            </el-col>

          </el-row>
          <el-row :gutter="20" style="margin-bottom: 10px">
            <el-col :span="2"></el-col>
            <el-col :span="6">
              <el-form-item label="流量产品:">
                <el-input
                  v-model="FormData.flow_product"
                  placeholder="请输入流量产品"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="套餐类型:">
                <el-select
                  v-model="FormData.plan_type "
                  placeholder="请选择流量类型"
                  clearable
                >
                  <el-option
                    v-for="plans in planList"
                    :key="plans.value"
                    :value="plans.value"
                    :label="plans.label"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="卡状态:">
                <el-select
                  v-model="FormData.status"
                  placeholder="请选择卡状态"
                  clearable
                >
                  <el-option
                    v-for="card in cardList"
                    :key="card.value"
                    :value="card.value"
                    :label="card.label"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <!-- <el-col :span="6">
              <el-form-item label="系统运营商id:">
                <el-input
                  v-model="FormData.operator_id"
                  placeholder="请输入系统运营商id"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col> -->
            <el-col :span="1">
              <div class="BtnSearch" @click="searchBtn()">查询</div>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <!-- 表格部分 -->
      <div class="tableDiv">
        <el-table
          :data="tableData"
          border
          v-loading="loading"
          height="480px"
          style="width: 90%; margin: 20px auto; overflow-y: auto"
        >
          <el-table-column label="序号" type="index" align="center" width="60">
            <template #default="{ $index }">
              {{ (currentPage - 1) * pageSize + $index + 1 }}
            </template>
          </el-table-column>
          <el-table-column label="卡号" prop="card_number" width="110" />
          <el-table-column label="运营商" prop="operator" width="90">
            <template #default="scope">
              <span v-if="scope.row.operator == 1">移动</span>
              <span v-if="scope.row.operator == 2">联通</span>
              <span v-if="scope.row.operator == 3">电信</span>
            </template>
        </el-table-column>
          <el-table-column label="流量类型" prop="flow_type" width="90">
            <template #default="scope">
              <span v-if="scope.row.flow_type == 0">可设置</span>
              <span v-if="scope.row.flow_type == 1">全国流量</span>
              <span v-if="scope.row.flow_type == 2">本地流量</span>
            </template>
        </el-table-column>
          <el-table-column label="套餐类型" prop="plan_type" width="90">
            <template #default="scope">
              <span v-if="scope.row.plan_type == 0">全部</span>
              <span v-if="scope.row.plan_type == 1">年套餐</span>
              <span v-if="scope.row.plan_type == 2">月套餐</span>
              <span v-if="scope.row.plan_type == 3">半年（每月）</span>
              <span v-if="scope.row.plan_type == 4">季度（每月）</span>
              <span v-if="scope.row.plan_type == 5">年（每月）</span>
              <span v-if="scope.row.plan_type == 6">季度套餐</span>
              <span v-if="scope.row.plan_type == 7">半年套餐</span>
            </template>
        </el-table-column>
          <el-table-column label="流量产品" prop="flow_product" width="90" />
          <el-table-column label="本月总流量(M)" prop="total_flow_this_month" width="115" />
          <el-table-column label="已用流量(M)" prop="used_flow" width="100" />
          <el-table-column label="剩余流量(M)" prop="remaining_flow" width="100" />
          <el-table-column label="激活时间" prop="activation_time" width="160" :formatter="formatDate"/>
          <el-table-column label="到期时间" prop="expiration_time" width="160" :formatter="formatDate"/>
          <el-table-column label="最后更新流量时间" prop="last_flow_update_time" width="160" :formatter="formatDate"/>
          <el-table-column label="卡状态" prop="status" width="90">
            <template #default="scope">
              <span v-if="scope.row.status == 1">待激活</span>
              <span v-if="scope.row.status == 2">使用中</span>
              <span v-if="scope.row.status == 3">已关机</span>
            </template>
        </el-table-column>
          <el-table-column label="开卡日期" prop="open_card_date" width="160" :formatter="formatDate"/>
          <el-table-column label="激活截止日期" prop="activation_deadline" width="160" :formatter="formatDate" />
          <el-table-column label="系统运营商id" prop="operator_id" width="90" />
          <el-table-column
            label="操作"
            width="230px"
            fixed="right"
            align="center"
          >
            <template #default="scope">
              <div class="btntext">
                <el-link
                  type="success"
                  @click="openQrCode(scope.$index, scope.row)"
                  >续费
                </el-link>
                <el-link type="primary" @click.stop="handleActivate(scope.$index, scope.row)">激活</el-link>
                <!-- <el-link type="danger" @click.stop="handleSuspend(scope.$index, scope.row)">暂停</el-link> -->
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination">
          <el-pagination
            :current-page="FormData.page"
            :page-size="pageSize"
            :small="small"
            layout="total, slot, prev, pager, next, jumper"
            :total="total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          >
            <template #default>
              <span class="el-pagination__total">{{ Math.ceil(total / pageSize) }} 页 </span>
            </template>
          </el-pagination>
        </div>
        <!-- 套餐续费 -->
        <div v-dialogdrag>
          <el-dialog  
            v-model="Dialog.editLevy"  
            title="套餐续费"  
            width="600px"  
            :before-close="closeDialog"  
            class="dialog"  
          >  
        <template #default>  
          <div class="card-container">  
          </div>
        </template> 
    <template #footer>  
      <div style="display: flex;justify-content: center;">
            <div>
            <div class="mealEwm" style="width: 200px;margin-bottom: 50px;" ref="qrCodeUrl" ></div>
            </div>
          </div>
          <div>
            <span class="dialog-footer">  
        <el-button 
            class="Btn" 
            type="primary"
            @click="submitPay"
            >已支付</el-button>  
        <el-button 
        class="Btn" 
        @click="closeDialog"
        >狠心离开</el-button>  
      </span>  
          </div>
          </template>  
        </el-dialog>  
        </div>
        <el-button plain @click="open">请确认</el-button>
      </div>
    </div>
  </div>
</template>
<script>
// import { onMounted } from "vue";
import { ref } from "vue";
import { onMounted, reactive, toRefs } from "vue-demi";
import {
  sel_card,
  manage_device,
  operator_data,
  active_card,
  card_pay
} from "@/request/api";
import { ElMessageBox, ElMessage } from "element-plus";
import QRCode from "qrcodejs2";
export default {
  name: "card_search",
  setup() {
    const data = reactive({
      //表单数据
      operList: [
        {
          value: 1,
          label: "移动",
        },
        {
          value: 2,
          label: "联通",
        },
        {
          value: 3,
          label: "电信",
        },
      ],
      flowList: [
        {
          value: 0,
          label: "可设置",
        },
        {
          value: 1,
          label: "全国流量",
        },
        {
          value: 2,
          label: "本地流量",
        },
      ],
      planList: [
        {
          value: 0,
          label: "全部",
        },
        {
          value: 1,
          label: "年套餐",
        },
        {
          value: 2,
          label: "月套餐",
        },
        {
          value: 3,
          label: "半年（每月）",
        },
        {
          value: 4,
          label: "季度（每月）",
        },
        {
          value: 5,
          label: "年（每月）",
        },
        {
          value: 6,
          label: "季度套餐",
        },
        {
          value: 7,
          label: "半年套餐",
        },
      ],
      cardList: [
        {
          value: 1,
          label: "待激活",
        },
        {
          value: 2,
          label: "使用中",
        },
        {
          value: 3,
          label: "已停机",
        },
      ],
      oper: "",
      flow: "",
      plans: "",
      card: "",
      //   cardList:"",
      FormData: {
        actionTime: ["", ""],
        page: 1,
      },
      tableData: [],
      tableData1: [],
      charg_list: [],
      chargLists: [],
      chargList2: [],
      chargList4: [],
      //   弹框
      Dialog: {
        editLevy: false,
        DialogData: {},
      },
      selectedCardIndex: -1,
      info: {
        taono: [],
        operators: [],
        cttype: [],
        cj_product: []
      },
      Dialog1: {
        editLevy: false,
        DialogData: {},
      },
      // price_id: "",
      loading: false,
      // isDeviceIdVisible: false,
      //   分页
      currentPage: 1,
      pageSize: 20,
      total: 0,
      checked: 0,
      small: true,

      temps: [],
      isadmin: false,
      users: [],
    });

    let qrcode = null;   
    const qrCodeUrl = ref("");
    const code_url = ref(""); 
    const searchBtn = () => {
      data.FormData.page = 1;
      getList();
    };
    
    const closeDialog = () => {
      data.Dialog.editLevy = false;
      data.Dialog1.editLevy = false;
      if (qrcode) {  
        qrcode.clear(); // 清除二维码  
        qrCodeUrl.value.innerHTML = ""; // 清空二维码容器  
        qrcode = null; // 将二维码变量设为 null，以便垃圾回收  
    }  
    };

    // 提交
    // const submitDialog = () => {
    //   submitData();
    // };
    // const submitData = () => {
    //   const dataa = data.Dialog.DialogData;
    //   upd_station(dataa).then((res) => {
    //     console.log(res);
    //     if (res) {
    //       closeDialog();
    //       if (res.code == 200) {
    //         ElMessage({
    //           showClose: true,
    //           message: "续费成功",
    //           type: "success",
    //         });
    //         getList();
    //       } else {
    //         ElMessage.error(res.msg);
    //       }
    //     } else {
    //       ElMessage.error("续费失败");
    //     }
    //   });
    // };

    // 续费
    const openQrCode = (index,row) => {
      // console.log(code_url,'code_url');
      console.log('row', row);
      const dataa = {
        card_number: row.card_number,
        flow_product: row.flow_product,
        operators: ""
      }
      console.log(dataa);
      
      const newoperator = data.operList.find((item) => {
        console.log(item.value,'item.value');
        return item.value == row.operator;
      });
      // if (newoperator) {
      //   console.log(dataa,'dataa');
      //   return dataa.operators = newoperator.label;
      //  }
      dataa.operators = newoperator.label;

      data.Dialog.editLevy = true;
      // item.value == res.operator return item.label;
      card_pay(dataa).then((res) => {
        console.log(res.msg.code_url,'res.msg.code_url');
        
        setTimeout(() => {  
          qrcode = new QRCode(qrCodeUrl.value, {  
            text: res.msg.code_url, // 需要转换为二维码的内容  
            width: 200,  
            height: 200,  
            colorDark: "#000000",  
            colorLight: "#ffffff",  
            correctLevel: QRCode.CorrectLevel.H,  
          });  
          code_url.value = res.msg.code_url;  
        }, 1);  
        // console.log(res.msg.code_url, 'res.msg.code_url');
      });
    };

    const submitPay = () => {
      getList();
      closeDialog();
    }
    const closeQrCodeDialog = () => {  
      data.Dialog1.editLevy = false;
      if (qrcode) {  
            qrcode.clear(); 
            qrCodeUrl.value.innerHTML = ""; 
            qrcode = null; 
      }   
    }

    const getList = () => {
      const dataa = {
        card_number: data.FormData.card_number,
        operator: data.FormData.operator,
        flow_type: data.FormData.flow_type,
        plan_type: data.FormData.plan_type,
        flow_product: data.FormData.flow_product,
        status: data.FormData.status,
        operator_id: data.FormData.operator_id,
        operator_id:sessionStorage.getItem("userID"),
      };
      data.loading = true;
      sel_card(dataa).then((res) => {
        console.log(res, "物联网卡列表");
        if (res) {
          data.loading = false;
          if (res.code == 200) {
            data.tableData = res.data;
            data.total = res.data.length;
          } else {
            ElMessage.error(res.msg);
          }
        } else {
          ElMessage.error("数据获取失败");
        }
      });
    };
    const formatDate = (row, column) => {
      // 获取单元格数据
      let data = row[column.property];
      if (data == null) {
        return null;
      }
      let now = new Date(data);
      let year =
        now.getFullYear() < 10 ? "0" + now.getFullYear() : now.getFullYear(); //得到年份
      let month =
        now.getMonth() + 1 < 10
          ? "0" + (now.getMonth() + 1)
          : now.getMonth() + 1; //得到月份
      let date = now.getDate() < 10 ? "0" + now.getDate() : now.getDate(); //得到日期
      let hour = now.getHours() < 10 ? "0" + now.getHours() : now.getHours(); //得到小时数
      let minute =
        now.getMinutes() < 10 ? "0" + now.getMinutes() : now.getMinutes(); //得到分钟数
      let seconds =
        now.getSeconds() < 10 ? "0" + now.getSeconds() : now.getSeconds(); //得到秒数

      return (
        year +
        "-" +
        month +
        "-" +
        date +
        " " +
        hour +
        ":" +
        minute +
        ":" +
        seconds
      );
    };
    const getdevList = () => {
      data.loading = true;
      manage_device().then((res) => {
        console.log(res, "manage_device");
        if (res) {
          data.loading = false;
          if (res.code == 200) {
            data.tableData1 = res.data;
          } else {
            ElMessage.error(res.msg);
          }
        } else {
          ElMessage.error("数据获取失败");
        }
      });
      console.log(data.options, "options");
    };
    const handleSizeChange = (val) => {
      data.pageSize = val;
    };
    const handleCurrentChange = (val) => {
      data.FormData.page = val;
      getList();
      //
    };
    const getUsername = () => {
      const username1 = localStorage.getItem("username");
      const users1 = [];
      operator_data().then((res) => {
        console.log(res, "sssssss");
        data.users = res;
        res.forEach((a) => {
          users1.push(a.username);
        });
        if (users1.indexOf(username1) == -1) {
          data.isadmin = true;
        } else {
          data.isadmin = false;
          data.FormData.username = username1;
        }
      });
    };

    // 激活
    const handleActivate = (index, row) => {
      const data2 = {
        card_no: row.card_no,
        code: 1,
      };
      ElMessageBox.confirm("您确认要激活吗?", "警告", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        active_card(data2).then((res) => {
          console.log(res, "0000000ress");
          if (res) {
            console.log(res, "初始化posttttttt");
            data.loading = false;
            if (res.code == 200) {
              ElMessage.success("操作成功");
              getList();
            } else {
              ElMessage.error(res.msg);
            }
          } else {
            ElMessage.error("操作失败");
          }
        });
      });
    };
      
    // 暂停使用
    // const handleSuspend = (index, row) => {
    //   const data2 = {
    //     card_no: row.card_no,
    //     code: 1,
    //   };
    //   ElMessageBox.confirm("您确认临时停用?", "警告", {
    //     confirmButtonText: "确认",
    //     cancelButtonText: "取消",
    //     type: "warning",
    //   }).then(() => {
    //     stop_card(data2).then((res) => {
    //       console.log(res, "0000000ress");
    //       if (res) {
    //         console.log(res, "初始化posttttttt");
    //         data.loading = false;
    //         if (res.code == 200) {
    //           ElMessage.success("操作成功");
    //           getList();
    //         } else {
    //           ElMessage.error(res.msg);
    //         }
    //       } else {
    //         ElMessage.error("操作失败");
    //       }
    //     });
    //   });
    // };
    onMounted(() => {
      console.log(data);
      getList();
      getUsername();
      console.log(qrCodeUrl.value);
    });
    return {
      ...toRefs(data),
      ref,
      qrCodeUrl,
      searchBtn,
      getUsername,
      closeDialog,
      // submitDialog,
      // submitData,
      getList,
      getdevList,
      handleSizeChange,
      handleCurrentChange,
      handleActivate,
      // handleSuspend,
      submitPay,
      formatDate,
      openQrCode,
      closeQrCodeDialog,
    };
  },
};
</script>
<style scoped>
.formDiv {
  margin-top: 10px;
  height: 150px;
}
.el-row {
  height: 45px;
}
.el-select /deep/ .el-input__wrapper {
  width: 500px !important;
  --el-input-focus-border-color: #7bd8d3;
}
.btn {
  color: #fff;
  background-color: #03beb0;
  border-color: #03beb0;
  width: 55px;
  height: 28px;
  line-height: 28px;
  margin-left: -100px;
}
.Btns {
  color: #fff;
  background-color: #03beb0;
  border-color: #03beb0;
  width: 175px;
  margin-left: 40px;
  margin-top: 20px;
}
.btn1 {
  margin-left: 340px;
}
.btn:hover {
  background-color: #05d5c7;
  border-color: #05d5c7;
}

.btn:focus {
  background-color: #03beb0;
  border-color: #03beb0;
}

.pagination {
  display: flex;
  justify-content: space-between;
  padding: 0 67px;
  margin-top: -10px;
}

.bottomDiv {
  height: 100%;
}
.input-with-select {
  width: 10vw;
}
.el-link {
  margin: 0 3px;
}
.card-container{
  display: flex;
  margin: 0 100px;
}
.card-container >p{
  font-size: 22px;
  margin-bottom: 10px;
}
.card-header > span{
  font-size: 18px;
}
.text-item {  
  font-size: 16px; /* 调整字体大小 */  
  margin-top: 10px; /* 添加上边距 */  
  margin-bottom: 10px; /* 添加下边距 */  
}  
.text-item-1 {  
  font-size: 18px;  
  margin-top: 15px;  
  margin-bottom: 15px;  
}  

.text-item-2 {  
  font-size: 14px;  
  margin-top: 8px;  
  margin-bottom: 8px;  
}  
</style>
